import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Header from '../component-elements/header';
import Breadcrumb from '../component-elements/breadcrumb';
import CategoryHeader from '../components/shop-category/header';
import ProductGrid from '../components/shop-category/product-grid';

import CmsImageComponent from '../content-elements/image';
import CmsBrandContactComponent from '../content-elements/brandContact';
import CmsShopCategoryComponent from '../content-elements/shopCategory';
import CmsTextTeaserComponent from '../content-elements/textTeaser';
import CmsBrandsSliderComponent from '../content-elements/brandSlider';
import CmsTextSimpleComponent from '../content-elements/textSimple';
import CmsNewsTeaserComponent from '../content-elements/newsTeaser';
import FlexibleTeaserComponent from '../content-elements/teaser';
import ImageTextElement from '../content-elements/imageTextElement';
import CmsResponsiveTableComponent from '../content-elements/responsiveTable';
import CmsVtour from '../content-elements/CmsVtour';
import CmsImageGallery from '../content-elements/GalleryImages';
import MapTextElement from '../content-elements/mapTextElement';

const components = {
  DatoCmsTextSimple: CmsTextSimpleComponent,
  DatoCmsImage: CmsImageComponent,
  DatoCmsBrandContact: CmsBrandContactComponent,
  DatoCmsShopCategoryTeaser: CmsShopCategoryComponent,
  DatoCmsImageTextTeaser: CmsTextTeaserComponent,
  DatoCmsBrandSlider: CmsBrandsSliderComponent,
  DatoCmsNewsTeaser: CmsNewsTeaserComponent,
  DatoCmsImageTextElement: ImageTextElement,
  DatoCmsImageGallery: CmsImageGallery,
  DatoCmsResponsiveTable: CmsResponsiveTableComponent,
  DatoCmsVtour: CmsVtour,
  DatoCmsTeaserFlexible: FlexibleTeaserComponent,
  DatoCmsMapText: MapTextElement,
};

const LatestProductsTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags: helmetProps,
      header: headerElements = [],
      content: cmsContent = [],
      metaRobots,
      metaCanonical,
      textcolorHeader,
      textcolorHeaderMobile,
      ...page
    },
    allMagentoProduct: {
      nodes: products,
    },
  },
  pageContext: {
    lang,
    url,
    productCount,
    productMinCreatedAt,
  },
  location,
}) => (
  <Layout textcolorHeader={textcolorHeader} textcolorHeaderMobile={textcolorHeaderMobile}>
    <Breadcrumb
      items={[
        { id: 'latest', name: page.name },
      ]}
    />

    {headerElements.length > 0 && (
      <Header data={headerElements[0]} />
    )}
    {headerElements.length === 0 && (
      <CategoryHeader
        headline={page.name}
      />
    )}

    <ProductGrid
      staticFilters={[
        {
          key: 'dw_created_at',
          values: [`${productMinCreatedAt}_`],
        },
      ]}
      items={products}
      initialItemTotal={productCount}
      lang={lang}
      location={location}
      breadcrumb={[
        { id: 'latest', name: page.name, url: `/${url}/` },
      ]}
    />

    {cmsContent.map((singleComponent, index) => {
      if (typeof singleComponent === 'object' && Object.keys(singleComponent).length > 0) {
        const Component = components[singleComponent.__typename];
        return (
          <Component
            key={singleComponent.id}
            {...singleComponent}
            swiperId={singleComponent.id}
          />
        );
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`unknown-${index}`}><h4>This content element can not be rendered.</h4></div>
      );
    })}

    <Helmet
      tags={helmetProps}
      metaRobots={metaRobots}
      metaCanonical={metaCanonical}
      title={page.seo?.title}
      titleFallback={t('cms_page_title_fallback', page.name)}
      url={url}
    />
  </Layout>
);

export default LatestProductsTemplate;

LatestProductsTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query latestProductsQuery(
        $lang: String!
        $url: String!
        $productsPerPage: Int!
        $productMinCreatedAt: Int!
    ) {
        datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
            ...SeoMetaTags
            ...header
            ...content
            metaCanonical
            metaRobots
            textcolorHeader
            textcolorHeaderMobile
            name
        }

        allMagentoProduct(
            filter: {
                locale: { eq: $lang }
                dw_created_at: {gte: $productMinCreatedAt}
            }
            limit: $productsPerPage
            sort: { fields: name, order: ASC }
        ) {
            totalCount
            nodes {
                ...shopProductListItem
            }
        }
    }
`;
